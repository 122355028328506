const shareModal = document.querySelector('#share-modal');
const shareButton = document.querySelector('.header_share');

launchShareModal = function () {
  // In video page, there are a number of actions set up
  // to close modals, including clicking the header.
  // As a cheap hack, setTimeout() makes this code trigger
  // AFTER the closeModals() script runs.
  setTimeout(() => {
    if (shareModal && shareButton) {
      shareModal.classList.remove("is-hidden");
      shareModal.classList.add("is-active");
      document.body.classList.add("is-clipped");

      // Accessibility
      ///  display modal to screen-readers
      shareModal.setAttribute('aria-hidden', 'false');
      ///  tell screen-readers to ignore other content
      shareModal.setAttribute('aria-modal', 'true');

      // toggle off Share Button
      shareButton.classList.add('is-hidden');
    }
  }, 0)
}

dismissShareModal = function () {
  if (shareModal && shareButton) {
    shareModal.classList.remove("is-active");
    shareModal.classList.add("is-hidden");
    document.body.classList.remove("is-clipped");

    // Accessibility
    shareModal.setAttribute('aria-hidden', 'true');
    shareModal.setAttribute('aria-modal', 'false');

    // Share Button
    shareButton.classList.remove('is-hidden');
  }
}

// test for local Storage
///  https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available#answer-41462752
storageAvailable = function (type = 'localStorage') {
  try {
    var storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
    return false;
  }
}

getFYAFStorage = function () {
  // if storage isn't available, we have nothing to get
  if (!storageAvailable()) return {};

  const rawFYAFStorage = localStorage.getItem('stlpr_living_ferguson');
  // if stlpr_living_ferguson isn't in localStorage, create it
  // and return an empty object
  if (rawFYAFStorage === null) {
    localStorage.setItem('stlpr_living_ferguson', '{}');
    return {};
  }

  return JSON.parse(rawFYAFStorage);
}

setFYAFStorage = function (object) {
  // if storage isn't available, we have no way to set
  if (!storageAvailable()) return;

  localStorage.setItem('stlpr_living_ferguson', JSON.stringify(object));
}

// Copy to Clipboard functionality
const shareInputs = document.querySelectorAll('input.share-button');

shareInputs.forEach(input => input.addEventListener('focus', (event) => {
  // prevent context-menu from being created
  event.preventDefault;

  // copy the hyperlink to the clipboard
  input.select();
  document.execCommand('copy');

  // get the label to add 'Copied!' text to
  const label = input.labels && input.labels[0];
  if (label) {
    const confirmation = label.querySelector('.copy-confirmation');
    confirmation.textContent = 'Copied!';
    setTimeout(() => confirmation.textContent = '', 2000);
  }
}));

toggleChapterNav = function () {
  menus = document.querySelectorAll(".chapter-nav-menu");

  menus.forEach(element => {
    element.classList.toggle("is-hidden");
  });


}